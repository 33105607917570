body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list-group-item{
  border: 1px solid rgba(0,0,0,.125) !important;
}

.modal-lg{
  width: 90vw !important;
  max-width: 90vw !important;
}
.modal-body{
  max-height: 80vh !important;
}

.modal-backdrop.show{
  opacity: 0.6 !important;
}

.row{
  margin-left: 0 !important;
  margin-right: 0 !important
}

.receipt-items > li{
  border: 0px solid white !important;
  font-weight: 800;
  font-size: 16px;
}

.serial-container > span:nth-child(odd){
  /* font-weight: bold !important; */
}